import React from 'react';

import styled, { keyframes } from 'styled-components';
/**
 * This is Footer.
 * @reactProps {Object} t - i18next Objekt für die Übersetzungen
 */

const LoadBackground = styled.div`
  width: 100%;
  height: 100%;
  position: ${props => (props.small ? 'relative' : 'fixed')};
  background-color: ${props => (props.small ? '' : 'rgba(0, 0, 0, 0.7)')};
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transform: ${props => (props.small ? 'scale(0.3)' : '')};
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Heartbeat = keyframes`

  0%
  {
    transform: scale( .75 );
  }
  12%
  {
    transform: scale( 1 );
  }
  24%
  {
    transform: scale( .75 );
  }
  36%
  {
    transform: scale( 1 );
  }
  48%
  {
    transform: scale( .75 );
  }
  100%
  {
    transform: scale( .75 );
  }
`;

const Image = styled.img`
height: auto;
width: 150px;
  animation: ${Heartbeat} 1.2s linear infinite;
`;

/**
 * Big Loader in Circle Design
 */
const LoadingScreen = ({ small }) => {
console.trace("i");
  return (
    <LoadBackground>
      <Image src="/static/images/loader_logo.png" alt="" />
    </LoadBackground>
  );
};

export default LoadingScreen;
